import { useEffect, useState } from "react";
import { toSeconds } from "iso8601-duration";
import { useSelector } from "react-redux";

import { getPickupReadinessTime } from "api/requests/getPickupReadinessTime";
import { getDomainCountryCode } from "redux/selectors/config";

export const useGetPickupReadinessTime = (dispensaryId: number) => {
  const [hasPickupLabel, setHasPickupLabel] = useState(false);
  const [pickupReadinessIsLoading, setPickupReadinessIsLoading] =
    useState(true);
  const [pickupReadinessErrors, setPickupReadinessErrors] = useState(false);
  const domainCountryCode = useSelector(getDomainCountryCode);

  useEffect(() => {
    getPickupReadinessTime({
      dispensaryId,
      domainCountryCode,
    })
      .then(hasPickupReadinessLabel)
      .then(setHasPickupLabel)
      .catch(() => setPickupReadinessErrors(true))
      .finally(() => setPickupReadinessIsLoading(false));
  }, [dispensaryId]);

  return {
    hasPickupLabel,
    pickupReadinessErrors,
    pickupReadinessIsLoading,
  };
};

const hasPickupReadinessLabel = (duration: Duration | null): boolean => {
  if (!duration) {
    return false;
  }

  const minutes = toSeconds(duration) / 60;
  return minutes < 30;
};
