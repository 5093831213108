import { Duration, parse } from "iso8601-duration";

import { reservationsApi } from "api/services/reservations";
import logError from "utils/logError";

export const getPickupReadinessTime = async ({
  domainCountryCode,
  dispensaryId,
}: {
  domainCountryCode: string;
  dispensaryId: number;
}): Promise<Duration | null> => {
  try {
    const { data } = await reservationsApi({ domainCountryCode }).get(
      `/dispensaries/${dispensaryId}/pickup_readiness_time`,
    );

    return data && data !== "" ? parse(data) : null;
  } catch (e) {
    logError(e.message || "Unknown error", {
      functionName: "getPickupReadinessTime",
      service: "reservations",
    });

    return null;
  }
};
