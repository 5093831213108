import { CartDispensary } from "custom-types/CartDispensary";
import { Dispensary } from "custom-types/Dispensary";

import getDispensaryPrimaryAddress from "./getDispensaryPrimaryAddress";

const buildDispensaryGoogleMapsUrl = (
  dispensary?: Dispensary | CartDispensary,
  withDirections?: boolean,
) => {
  if (!dispensary) {
    return;
  }

  const { name, address1, address2 } = dispensary;

  if (!address1 && !address2) {
    return;
  }

  const primaryAddress = getDispensaryPrimaryAddress(dispensary);

  const namePlusLocation = [encodeURIComponent(name), primaryAddress]
    .join(" ")
    .replace(/\s/g, "+");

  return withDirections
    ? `https://www.google.com/maps/dir/?api=1&destination=${namePlusLocation}`
    : `https://maps.google.com/?q=${namePlusLocation}`;
};

export default buildDispensaryGoogleMapsUrl;
