import {
  trackEvent as trackEventUtil,
  trackNonInteractionEvent,
} from "@leafly-com/web-utils";
import startCase from "lodash/startCase";

import type { Action, Category } from "constants/events";
import { CartItem } from "custom-types/Cart";

export type Dimension = {
  [name: string]: number | string | boolean | undefined | null;
};

export const trackEvent = (
  category: Category,
  action: Action,
  label?: string,
  customDimensions?: Dimension,
) => trackEventUtil(category, action, label, customDimensions);

export const trackMenuEvent = (
  field: string,
  label: string,
  {
    menuItemId,
    menuItem,
    category,
    cartId,
  }: {
    menuItemId?: string | number | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    retailType?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    menuItem?: any;
    category?: string | null;
    cartId?: string | null;
  } = {},
) => {
  const renamedFields = {
    Categories: "ProductType",
    Varieties: "Classification",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  } as any;

  let action = startCase(field).replace(/[^A-Za-z0-9]/g, "");

  if (renamedFields[action]) {
    action = renamedFields[action];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  const itemDimensions = {} as any;

  if (menuItem) {
    const featuredDeal = menuItem?.deal || menuItem?.offers?.[0];
    itemDimensions.menuItemId = menuItem.id || menuItem.menuItemId;
    itemDimensions.brandId = menuItem.brandId || menuItem.brand?.id;
    itemDimensions.productId = menuItem.productId || menuItem.product?.id;
    itemDimensions.strainId = menuItem.strainId || menuItem.strain?.id;
    itemDimensions.dispenaryId = menuItem.dispensaryId;
    itemDimensions.dealId = featuredDeal?.id || "no deal";
    itemDimensions.dealType = featuredDeal?.kind || "no deal";
    itemDimensions.dealDiscountType = featuredDeal?.discountType || "no deal";
  } else if (menuItemId) {
    itemDimensions.menuItemId = menuItemId;
  }

  if (cartId) {
    itemDimensions.cartId = cartId;
  }
  trackEventUtil(category || "Dispensary Menu", action, label, itemDimensions);
};

export const trackFilterEventForClients = (
  // @ts-ignore (fix me please, do not replicate)
  label,
  // @ts-ignore (fix me please, do not replicate)
  value,
  customDimensions = null,
) => {
  const clientTrackingLabel = {
    brand_name: "Brands",
    cbd_pips: "Cbd",
    pickup_available: "PickupFacet",
    price: "Price",
    product_category: "ProductType",
    strain_category: "Cultivars",
    strain_name: "Strains",
    thc_pips: "Thc",
    unit: "Unit",
  };

  const clientTrackingValue = {
    "cbd_content:asc": "CBD: Low",
    "cbd_content:desc": "CBD: High",
    "created:asc": "Oldest",
    "created:desc": "Newest",
    delivery_available: "Delivery",
    each: "Each",
    g: "Gram",
    "menu_item_name:asc": "A-Z",
    mg: "Milligram",
    oz: "Ounce",
    pc: "Piece",
    pickup_available: "Pickup",
    pickup_delivery_available: "Pickup & Delivery",
    "price:asc": "Price: Low",
    "price:desc": "Price: High",
    "thc_content:asc": "THC: Low",
    "thc_content:desc": "THC: High",
    "upvotes_count:asc": "Least Helpful",
    "upvotes_count:desc": "Most Helpful",
  };

  if (label === "pickup_available") {
    value = value === "Select All" ? "Off" : "On";
  }

  trackEventUtil(
    "Dispensary Menu",
    // @ts-ignore (fix me please, do not replicate)
    clientTrackingLabel[label] || label,
    // @ts-ignore (fix me please, do not replicate)
    clientTrackingValue[value] || value,
    customDimensions,
  );
};

export const trackInfoEvent = (
  field: string,
  label?: string,
  retailType?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  customDimension: any = null,
): void => {
  //TODO: Add GA Tracker for appointment page.
  const action = startCase(field).replace(/[^A-Za-z0-9]/g, "");
  if (retailType === "clinic") {
    trackEventUtil("Doctor Info", action, label, customDimension);
  } else {
    trackEventUtil("Dispensary Info", action, label, customDimension);
  }
};

export const trackReviewsEvent = (field: string, label?: string) => {
  const action = startCase(field).replace(/[^A-Za-z0-9]/g, "");
  trackEventUtil("Dispensary Reviews", action, label);
};

export const trackCheckoutEvent = (
  field: string,
  label: string,
  {
    bagId,
    category,
    cartItem,
    errorMessage,
    orderId,
    userId,
    subtotal,
    bogoDealCount,
    saleDealCount,
  }: {
    bagId?: string | number | null;
    category?: string | null;
    cartItem?: CartItem | null;
    errorMessage?: string | null;
    orderId?: string | null;
    userId?: string | number | null;
    subtotal?: number;
    bogoDealCount?: number | null;
    saleDealCount?: number | null;
  },
  isNonInteractionEvent = false,
) => {
  const action = startCase(field).replace(/[^A-Za-z0-9]/g, "");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  let customDimensions = {} as any;

  if (cartItem && cartItem.menuItemData) {
    const {
      brandId,
      dispensaryId,
      id: menuItemId,
      productId,
      strainId,
    } = cartItem.menuItemData;

    customDimensions = {
      brandId,
      dispensaryId,
      menuItemId,
      productId,
      strainId,
    };
  }

  if (bagId) {
    customDimensions.bagId = bagId;
  }

  if (orderId) {
    customDimensions.orderId = orderId;
  }

  if (errorMessage) {
    customDimensions.errorMessage = errorMessage;
  }

  if (userId !== undefined) {
    // if userId is null that means guest checkout... seems significant
    customDimensions.userId = userId;
  }

  if (subtotal) {
    customDimensions.subtotal = subtotal;
  }

  if (bogoDealCount !== undefined || saleDealCount !== undefined) {
    customDimensions.bogoDealCount = bogoDealCount || 0;
    customDimensions.saleDealCount = saleDealCount || 0;
  }

  if (isNonInteractionEvent) {
    trackNonInteractionEvent(
      category || "Dispensary Reservations",
      action,
      label,
      customDimensions,
    );
  } else {
    trackEventUtil(
      category || "Dispensary Reservations",
      action,
      label,
      customDimensions,
    );
  }
};
