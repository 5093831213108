import React from "react";

import Placeholder, { Rect } from "components/Placeholder";

const PickupReadinessSkeleton: React.FC = () => (
  <Placeholder width="100%" height="36">
    <Rect rx="3" ry="3" width="100%" height="100%" />
  </Placeholder>
);

export default PickupReadinessSkeleton;
