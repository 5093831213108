import React, { useMemo } from "react";
import classnames from "classnames";

import { Dispensary } from "custom-types/Dispensary";
import {
  formatSchedule,
  getShortenedTimeZone,
  getTodayWeekday,
} from "utils/dispensaryScheduleUtils";

const RetailHours: React.FC<{
  className?: string;
  headerAs?: React.ElementType;
  schedules: Dispensary["schedules"];
  timeZone: Dispensary["timeZone"];
}> = ({ className, headerAs: Header = "h3", schedules, timeZone }) => {
  const shortenedTimeZone = useMemo(
    () => timeZone && getShortenedTimeZone(timeZone),
    [timeZone],
  );
  const schedule = useMemo(
    () => formatSchedule(schedules, "store"),
    [JSON.stringify(schedules)],
  );

  return (
    <div className={className}>
      <Header className="text-xs mb-sm uppercase">
        <span>Hours and Info</span>
        {shortenedTimeZone && <span>&nbsp;({shortenedTimeZone})</span>}
      </Header>
      {!schedule?.length ? (
        <div className="font-bold p-xs text-green">Hours unavailable</div>
      ) : (
        <div className="capitalize text-xs">
          {schedule.map((dailySchedule) => {
            const { day, open, close } = dailySchedule || {};
            const isToday = getTodayWeekday() === day;
            const dailyHours =
              open?.time && close?.time
                ? `${open.time} - ${close.time}`
                : "Closed";

            return (
              <div
                key={day}
                className={classnames("flex justify-between", {
                  "font-bold": isToday,
                  "text-grey": !isToday,
                })}
              >
                <div>{day}</div>
                <div
                  data-testid={`${day}-hours`}
                  className={classnames({ "font-bold": isToday })}
                >
                  {dailyHours}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default RetailHours;
