import React from "react";
import classnames from "classnames";

interface FulfillmentToggleProps {
  alwaysAllowPickup?: boolean;
  alwaysAllowDelivery?: boolean;
  deliveryAvailable: boolean;
  deliveryFee?: number | string;
  deliveryMin?: number | string;
  fulfillmentCookie?: "pickup" | "delivery";
  onClick: (isPickup: boolean) => void;
  pickupAvailable: boolean;
  preorderAvailable: boolean;
  hasAddress?: boolean;
}

export const FulfillmentToggle: React.FC<FulfillmentToggleProps> = ({
  alwaysAllowPickup = false,
  alwaysAllowDelivery = false,
  deliveryAvailable,
  deliveryFee,
  deliveryMin,
  fulfillmentCookie,
  onClick,
  pickupAvailable,
  preorderAvailable,
  hasAddress = true,
}) => {
  const deliveryOnly =
    !pickupAvailable && !preorderAvailable && deliveryAvailable;

  const pickupOnly = pickupAvailable && !deliveryAvailable;

  const isPickup = fulfillmentCookie === "pickup";

  const formatPrice = (price: number | string) =>
    price.toLocaleString("en-US", {
      currency: "USD",
      style: "currency",
    });

  const formattedDeliveryFee = !deliveryFee ? "Free" : formatPrice(deliveryFee);

  const formattedDeliveryMin = !deliveryMin
    ? "No minimum"
    : `$${deliveryMin} minimum`;

  const handlePickupClick = () => {
    if (fulfillmentCookie !== "pickup") {
      onClick(true);
    }
  };

  const handleDeliveryClick = () => {
    if (fulfillmentCookie !== "delivery") {
      onClick(false);
    }
  };

  return (
    <div className="p-sm bg-leafly-white rounded-full text-xs">
      <div className="relative flex items-center">
        <div
          className={classnames(
            "absolute h-full flex-1 rounded-full bg-default button-width shadow-low w-1/2 transition-[left] duration-200 ease-in-out ",
            {
              "left-0": isPickup,
              "left-1/2": !isPickup,
            },
          )}
        />

        <button
          aria-pressed={isPickup}
          className={classnames(
            "flex flex-col items-center h-full flex-1 z-10 transition-colors duration-200 ease-[ease]",
            {
              "cursor-default": deliveryOnly && !alwaysAllowPickup,
            },
          )}
          disabled={isPickup || (deliveryOnly && !alwaysAllowPickup)}
          onClick={handlePickupClick}
          data-testid="fulfillment-toggle__pickup"
        >
          <div
            className={classnames("font-bold", {
              "text-grey": deliveryOnly && !alwaysAllowPickup,
              "text-white": isPickup,
            })}
          >
            Pickup
          </div>
          <div
            className={classnames({
              "text-grey": !isPickup,
              "text-white": isPickup,
            })}
          >
            {pickupAvailable ? (
              <>
                Free
                <span> • </span>
                No minimum
              </>
            ) : preorderAvailable ? (
              "Preorder available"
            ) : (
              "Not available"
            )}
          </div>
        </button>

        <button
          aria-pressed={!isPickup}
          className="flex flex-col items-center h-full flex-1 z-10 transition-colors duration-200 ease-[ease]"
          disabled={!isPickup || (pickupOnly && !alwaysAllowDelivery)}
          onClick={handleDeliveryClick}
          data-testid="fulfillment-toggle__delivery"
        >
          <div
            className={classnames("font-bold", {
              "text-grey": pickupOnly,
              "text-white": !isPickup,
            })}
          >
            Delivery
          </div>
          <div
            className={classnames({
              "text-grey": isPickup,
              "text-white": !isPickup,
            })}
          >
            {deliveryAvailable ? (
              hasAddress ? (
                <>
                  {formattedDeliveryFee}
                  <span> • </span>
                  {formattedDeliveryMin}
                </>
              ) : (
                <>Address required</>
              )
            ) : (
              "Not available"
            )}
          </div>
        </button>
      </div>
    </div>
  );
};

export default FulfillmentToggle;
