import React, { useEffect, useState } from "react";

import { DeliveryServiceArea, Dispensary } from "custom-types/Dispensary";
import { useGetPickupReadinessTime } from "hooks/useGetPickupReadinessTime";
import { getPaymentMethodsString } from "utils/fulfillmentUtils";

import { Modal } from "components/botanic/Modal";
import RetailHours from "components/Dispensary/RetailHours";
import RetailerDetailsCard from "components/RetailerDetailsCard";

type Props = {
  dispensary: Dispensary;
  deliveryZone?: DeliveryServiceArea;
  onDismissed: () => void;
  returnFocusRef?: React.RefObject<HTMLElement>;
};

const RetailerDetailsModal: React.FC<Props> = ({
  dispensary,
  deliveryZone,
  onDismissed,
  returnFocusRef,
}) => {
  const {
    deliveryEnabled,
    hasDeliveryEnabled,
    pickupEnabled,
    hasReservationsEnabled,
    id: dispensaryId,
    timeZone,
    schedules,
    pickupPaymentMethods,
  } = dispensary;
  const showPickup = pickupEnabled || hasReservationsEnabled;
  const showDelivery = hasDeliveryEnabled || deliveryEnabled;
  const showStoreInfo = getPaymentMethodsString(pickupPaymentMethods);
  const [pickupReadinessLabel, setPickupReadinessLabel] = useState("");

  const { hasPickupLabel, pickupReadinessIsLoading, pickupReadinessErrors } =
    useGetPickupReadinessTime(dispensaryId);

  useEffect(() => {
    if (hasPickupLabel && !pickupReadinessIsLoading && !pickupReadinessErrors) {
      setPickupReadinessLabel("Pickup ready in under 30 mins");
    }
  }, [hasPickupLabel, pickupReadinessIsLoading, pickupReadinessErrors]);

  return (
    <Modal
      onDismissed={onDismissed}
      returnFocusRef={returnFocusRef}
      title="Business Hours"
      width={500}
    >
      <RetailHours
        className="mb-6"
        timeZone={timeZone}
        schedules={schedules}
        headerAs="h2"
      />
      {showPickup && (
        <div className="pt-5 pb-2 border-t border-light-grey">
          <RetailerDetailsCard
            dispensary={dispensary}
            fulfillmentType="pickup"
            pickupReadinessLabel={pickupReadinessLabel}
          />
        </div>
      )}
      {showDelivery && (
        <div className="pt-6 border-t border-light-grey">
          <RetailerDetailsCard
            dispensary={dispensary}
            deliveryZone={deliveryZone}
            fulfillmentType="delivery"
          />
        </div>
      )}
      {!showDelivery && !showPickup && showStoreInfo && (
        <div className="pt-6 border-t border-light-grey">
          <RetailerDetailsCard
            dispensary={dispensary}
            fulfillmentType="store"
          />
        </div>
      )}
    </Modal>
  );
};

export default RetailerDetailsModal;
